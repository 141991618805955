import moment from 'moment';
import { HNF_WORKOUT, TRACK } from '@/graphql/queries';
import { setTrackData, updateTime } from '@/redux/slices/workout';
import { setLastUsedOrganizationId } from '@/redux/slices/auth';
import { GetTrack, GetUpdatedWods } from '@/ts/types';
import { GymTrack, Track, Workout, WorkoutData } from '@/ts/interfaces/workout';

export const getUpdatedWods: GetUpdatedWods = (wods, tracks, selectedWods) => {
  const updatedTracks = tracks.map((track) => {
    if (track.id === wods?.track_id) {
      return {
        ...track,
        workouts: track.workouts!.map((workout) =>
          workout.id === wods.id
            ? { ...workout, isCheck: wods.isCheck }
            : workout
        ),
      };
    }
    return track;
  });

  const updatedSelectedWods = wods.isCheck
    ? [...selectedWods, wods]
    : selectedWods.filter((selectedWod) => selectedWod.id !== wods.id);
  return {
    tracks: updatedTracks,
    selectedWods: updatedSelectedWods,
  };
};

export const getTrack: GetTrack = (
  trackEventIds,
  client,
  dispatch,
  lastUpdatedTime,
  organizationId,
  lastUsedOrganizationId,
  tracksDataFromStore
) => {
  const currentTime = moment().format('YYYY-MM-DD');

  if (!lastUpdatedTime) {
    dispatch(updateTime(currentTime));
  }
  // eslint-disable-next-line no-async-promise-executor
  const track = new Promise(async (resolve) => {
    const token = localStorage.getItem('token');
    const headers = {
      'x-hasura-role': 'cap_screen_display_organization',
      'x-hasura-org-id': organizationId,
      authorization: `Bearer ${token}`,
    };
    const { data } = await client.query({
      context: {
        headers: headers,
      },
      variables: {
        event_date: currentTime,
        organization_id: organizationId,
      },
      query: TRACK,
    });

    let new_tracks: Track[] = [];
    let selected: Workout[] = [];
    const workoutsIds = [];
    const isTrackIdsEmpty = trackEventIds?.length === 0;
    const tracks =
      data?.hansnfranz?.organization?.[0]?.gym?.gym_tracks?.map(
        (gymTrack: GymTrack) => gymTrack?.track
      ) || [];
    for (let i = 0; i < tracks?.length; i++) {
      const track = tracks[i];
      new_tracks.push({ name: track.name, id: track.id });
      const events = [];
      for (let j = 0; j < track.track_events.length; j++) {
        const event = track.track_events[j];
        const track_event_workout = event?.track_event_workout?.workout;
        let event_workout;
        if (isTrackIdsEmpty && trackEventIds!.length < 1) {
          trackEventIds?.push(event.id.toString());
        }
        const isCheck = trackEventIds?.includes(event.id.toString());
        if (track_event_workout) {
          workoutsIds.push(track_event_workout.id);
          event_workout = {
            workout_id: track_event_workout.id,
            id: event.id,
            movements: track_event_workout.workout_movements,
            isCheck: isCheck,
          };
        } else if (event.instructions) {
          event_workout = {
            id: event.id,
            name: event.title,
            description: event.instructions,
            movements: [],
            isCheck: isCheck,
          };
        }
        if (event_workout) {
          events.push(event_workout);
          if (isCheck) {
            selected.push(event_workout);
          }
        }
      }
      new_tracks[i].workouts = events;
    }
    let workouts: Workout[] = [];
    if (workoutsIds.length) {
      const { data } = await client.query({
        context: {
          headers: headers,
        },
        query: HNF_WORKOUT,
        variables: { ids: workoutsIds },
      });
      workouts = data?.hansnfranz?.workout;
      selected.forEach((v) => {
        const workout = workouts.find((w) => w.id === v.workout_id);
        if (workout) {
          v.name = workout.name;
          v.description = workout.description;
          v.remote_workout = workout.remote_workout;
        }
      });
    }

    const useNewData = lastUsedOrganizationId !== organizationId;
    dispatch(setLastUsedOrganizationId(organizationId));

    if (
      !useNewData &&
      lastUpdatedTime &&
      tracksDataFromStore?.tracksFromStore?.length &&
      tracksDataFromStore?.selectedWodsFromStore?.length
    ) {
      const { tracksFromStore, selectedWodsFromStore } = tracksDataFromStore;

      if (moment(currentTime).isAfter(lastUpdatedTime, 'day')) {
        const sameTrack = new_tracks.find((track) =>
          selectedWodsFromStore.some((wod) => wod.track_id === track.id)
        );

        if (sameTrack) {
          const newSelectedWods = sameTrack
            .workouts!.map((workout: Workout) => {
              const matchingWorkout = workouts.find(
                (w) => w.id === workout.workout_id
              );
              if (matchingWorkout) {
                return {
                  ...workout,
                  name: matchingWorkout.name,
                  description: matchingWorkout.description,
                  remote_workout: matchingWorkout.remote_workout,
                  isCheck: true,
                };
              }
              return workout;
            })
            .slice(0, 4);

          const updatedNewTracks = new_tracks.map((track) => {
            if (track.id === sameTrack.id) {
              const updatedWorkouts = track.workouts!.map(
                (workout: Workout) => {
                  const foundWod = newSelectedWods.find(
                    (w: Workout) => w.id === workout.id
                  );
                  return {
                    ...workout,
                    isCheck: !!foundWod,
                  };
                }
              );
              return {
                ...track,
                workouts: updatedWorkouts,
              };
            } else {
              return {
                ...track,
                workouts: track.workouts!.map((workout: Workout) => ({
                  ...workout,
                  isCheck: false,
                })),
              };
            }
          });

          new_tracks = updatedNewTracks;
          selected = newSelectedWods;
        }
        dispatch(updateTime(currentTime));
      } else {
        new_tracks = tracksFromStore;
        selected = selectedWodsFromStore;
      }
    }

    resolve({
      tracks: new_tracks,
      selectedWods: selected,
      workouts: workouts,
    });
  });

  track
    .then((tracks) => {
      dispatch(setTrackData(tracks as WorkoutData));
    })
    .catch((err) => console.error('Error fetching track data:', err));
};
